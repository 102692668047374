import { memo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { UncontrolledAlert } from 'reactstrap';

import { loadUninitializedAndDisconnectedControllers } from '@/apiHelpers/loadUninitializedAndDisconnectedControllers';

export const AlertBarSensor = memo(({ facility }) => {
  const [uninitializedSensors, setUninitializedSensors] = useState([]);
  const [disconnectedSensors, setDisconnectedSensors] = useState([]);

  useEffect(() => {
    if (!facility) {
      return;
    }

    let mounted = true;
    loadUninitializedAndDisconnectedControllers(facility.id).then(
      ({ uninitSensors, disconnectedEnvs }) => {
        if (mounted) {
          setUninitializedSensors(uninitSensors);
          setDisconnectedSensors(disconnectedEnvs);
        }
      },
    );
    return () => {
      mounted = false;
    };
  }, [facility]);

  return (
    <header
      id="alertBar"
      style={{
        paddingBottom: '0px',
      }}
    >
      {uninitializedSensors &&
        uninitializedSensors.map(s => (
          <UncontrolledAlert
            key={s.id}
            color="danger"
            style={{
              paddingBottom: '5px',
              paddingTop: '5px',
              marginBottom: '0px',
            }}
          >
            {s.name} Sensor
            {s.displayParameterType ? ', ' + s.parameterType + ' ' : ''} unable
            to initialize
          </UncontrolledAlert>
        ))}

      {disconnectedSensors &&
        disconnectedSensors.map(s => (
          <UncontrolledAlert
            key={s.id}
            color="danger"
            style={{
              paddingBottom: '5px',
              paddingTop: '5px',
              marginBottom: '0px',
            }}
          >
            {s.name} Sensor
            {s.displayParameterType ? ', ' + s.parameterType + ' ' : ''} lost
            connection
          </UncontrolledAlert>
        ))}
    </header>
  );
});

AlertBarSensor.displayName = 'AlertBarSensor';
