import { lazy } from 'react';

import { PrivateRoute } from '../components/common/PrivateRoute';

const IrrigationLogs = lazy(() => import('../pages/logs/IrrigationLogsPage'));
const ProductAdjustments = lazy(() =>
  import('../pages/logs/ProductAdjustments'),
);

export const logsRoutes = [
  {
    path: '/logs/irrigation_logs',
    name: 'Irrigation Logs',
    component: IrrigationLogs,
    route: PrivateRoute,
    allowedPermission: 'LOGS_IRRIGATION_LOGS_TAB',
  },
  {
    path: '/logs/product-adjustments',
    exact: true,
    component: ProductAdjustments,
    route: PrivateRoute,
    allowedPermission: 'LOGS_PRODUCT_ADJUSTMENTS_TAB',
  },
];
