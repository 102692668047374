import axios from 'axios';

const PATH = '/history/feedback_control_zones';

export const FeedbackControlZonesHistoryService = {
  query: async function (options) {
    if (options.createNew) {
      return await axios.post(PATH, options);
    } else {
      return (
        await axios.post(PATH, {
          environmentalControlZoneIds: options.environmentalControlZoneIds,
          outputIds: options.outputIds,
          facilityId: options.facilityId,
          startTime: options.startTime,
          endTime: options.endTime,
        })
      ).data;
    }
  },
};
