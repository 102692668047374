import { lazy } from 'react';

import { PrivateRoute } from '../components/common/PrivateRoute';

const CultivationRecipesConfiguration = lazy(() =>
  import('../pages/configurations/CultivationRecipesConfigurationPage'),
);
const CultivationRecipeConfiguration = lazy(() =>
  import('../pages/configurations/CultivationRecipeConfigurationPage'),
);

export const recipesRoutes = [
  {
    path: '/recipes/cultivationRecipes',
    exact: true,
    name: 'Cultivation Recipes Configuration',
    component: CultivationRecipesConfiguration,
    route: PrivateRoute,
    allowedPermission: 'RECIPES_CULTIVATION_RECIPES_TAB',
  },
  {
    path: '/recipes/cultivationRecipes/:id',
    exact: true,
    name: 'Cultivation Recipe Configuration',
    component: CultivationRecipeConfiguration,
    route: PrivateRoute,
    allowedPermission: 'RECIPES_CULTIVATION_RECIPES_TAB',
  },
];
