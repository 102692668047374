import { useEffect } from 'react';

export const useClickOutside = (ref, onClick) => {
  useEffect(() => {
    const handleClick = e => {
      if (
        ref.current &&
        ref.current !== e.target &&
        !ref.current.contains(e.target)
      ) {
        onClick(e);
      }
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [ref, onClick]);
};
