import { createTheme } from '@mui/material/styles';

export const createThemeObject = (mode = 'light') => {
  let theme = createTheme({
    palette: {
      mode,
      primary: {
        main: 'rgb(114, 101, 183)',
      },
      rootBackground: {
        default: mode === 'dark' ? 'rgb(40, 40, 40)' : 'rgb(111, 121, 129)',
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '15px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            width: '100%',
            margin: 0,
            overflow: 'hidden',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
              cursor: 'pointer',
            },
            [theme.breakpoints.down('sm')]: {
              boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2)',
              zIndex: 1,
            },
            '& + .MuiDialogContent-root': {
              [theme.breakpoints.down('sm')]: {
                paddingTop: '1rem; !important',
              },
            },
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: '1rem',
            [theme.breakpoints.down('sm')]: {
              paddingTop: '1.25rem',
            },
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: '1rem',
            [theme.breakpoints.down('sm')]: {
              boxShadow: '0px -3px 3px -2px rgba(0,0,0,0.2)',
              zIndex: 1,
            },
          }),
        },
      },
      MuiPopper: {
        styleOverrides: {
          root: {
            zIndex: 1400,
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: ({ theme }) => ({
            [theme.breakpoints.down('sm')]: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }),
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            color: { light: '#343a40', dark: '#ffffff' }[mode],
          },
          h2: {
            color: { light: '#343a40', dark: '#ffffff' }[mode],
          },
          h3: {
            color: { light: '#343a40', dark: '#ffffff' }[mode],
          },
          h4: {
            color: { light: '#343a40', dark: '#ffffff' }[mode],
          },
          h5: {
            color: { light: '#343a40', dark: '#ffffff' }[mode],
          },
          h6: {
            color: { light: '#343a40', dark: '#ffffff' }[mode],
          },
          body1: {
            color: { light: '#000000a9', dark: '#ffffff' }[mode],
          },
          body2: {
            color: { light: '#000000a9', dark: '#ffffff' }[mode],
          },
        },
      },
    },
  });

  return theme;
};
