import { useCallback, useContext, useEffect, useState } from 'react';

import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

import Container from '@mui/material/Container';

import { UserContext } from '@providers/UserProvider';

import { AlertBar } from './AlertBar';
import { AlertBarSensor } from './AlertBarSensor';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { RightSideBar } from './RightSidebar';
import { RightSidebarContent } from './RightSidebarContent';
import { Topbar } from './Topbar';

export const AuthLayout = ({ children }) => {
  const history = useHistory();
  const { facility, currentUser } = useContext(UserContext);
  const [dateTime, setDateTime] = useState();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggleMenu = useCallback(
    e => {
      e.preventDefault();
      setIsMenuOpened(!isMenuOpened);
    },
    [isMenuOpened],
  );

  const toggleRightSidebar = useCallback(() => {
    document.body.classList.toggle('right-bar-enabled');
  }, []);

  useEffect(() => {
    if (!facility?.timeZone) {
      return;
    }
    setDateTime(moment().tz(facility.timeZone).format('h:mm A'));
    const minTimer = setInterval(() => {
      setDateTime(moment().tz(facility.timeZone).format('h:mm A'));
    }, 1000 * 60);

    return () => clearInterval(minTimer);
  }, [facility]);

  useEffect(() => {
    if (
      currentUser.invite_pending &&
      !sessionStorage.getItem('invite_accepted')
    ) {
      history.push('/register?invite=true');
    }
  }, []);

  return (
    <div className="app">
      <header id="topnav">
        <Topbar
          rightSidebarToggle={toggleRightSidebar}
          menuToggle={toggleMenu}
          isMenuOpened={isMenuOpened}
        />
        <Navbar
          isMenuOpened={isMenuOpened}
          menuToggle={() => setIsMenuOpened(false)}
        />
      </header>
      <div className="wrapper">
        <div className="alerts">
          <AlertBar facility={facility} />
          <AlertBarSensor facility={facility} />
        </div>
        <Container
          maxWidth={false}
          style={{
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {dateTime && currentUser.role === 1 && (
            <h5 color="secondary">Local Time: {`${dateTime}`}</h5>
          )}
          {children}
        </Container>
      </div>
      <Footer />
      <RightSideBar title={'Settings'}>
        <RightSidebarContent />
      </RightSideBar>
    </div>
  );
};
