import axios from 'axios';

const PATH = '/type_phase';

export const PhasesService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  create: async phase => await axios.post(PATH, phase),
  update: async phase => await axios.put(PATH, phase),
  delete: async id => await axios.delete(`${PATH}/${id}`),
};
