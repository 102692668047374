import axios from 'axios';

const PATH = '/types/trays';

export const TrayTypesService = {
  list: async params => (await axios.get(PATH, { params })).data,
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  create: payload => axios.post(PATH, payload),
  archive: id => axios.delete(`${PATH}/${id}`),
};
