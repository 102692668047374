import React, { useState } from 'react';

import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  menuButton: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '0.5rem',
    padding: '1rem',
    fontSize: '14px',
    borderRadius: '0.75rem',
    color: props => (props.anchorEl ? 'white' : '#6f7981'),
    backgroundColor: props =>
      props.anchorEl ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
  },
  menuIcon: {
    marginLeft: '0.5rem',
    marginTop: '-0.25rem',
  },
}));

export const ProfileDropdown = ({ menuItems, username }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ anchorEl });

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className={classes.menuButton}
        onClick={handleMenuOpen}
        aria-expanded={Boolean(anchorEl)}
      >
        {username}
        <Box className={classes.menuIcon}>
          <ExpandMoreIcon fontSize="14px" />
        </Box>
      </IconButton>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>
          <h6>Welcome !</h6>
        </MenuItem>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index + '-profile-menu'}
            component={RouterLink}
            to={item.redirectTo}
            onClick={handleMenuClose}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '14px', color: '#6f7981' }}
              primary={item.label}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
