import axios from 'axios';

const PATH = '/environmental_zones';

export const EnvironmentalZonesService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  create: async payload => (await axios.post(PATH, payload)).data,
  update: async payload => (await axios.put(PATH, payload)).data,
  delete: async id => await axios.delete(`${PATH}/${id}`),
  findConflicting: async payload =>
    (await axios.post(`${PATH}/find-conflicting`, payload)).data,
};
