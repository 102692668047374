export const checkPermissionAccess = (
  allowedPermission,
  currentUser,
  facility,
  rolesData,
  adminRequired,
) => {
  if (adminRequired && !currentUser?.is_admin) return false;

  if (!allowedPermission) return true;

  if (currentUser?.is_admin) return true;

  if (!facility || !rolesData) return true;

  // check if user is admin return false
  const choosenFacilityPermissions = currentUser.permissions.find(
    ({ facility_id }) => {
      return facility_id === facility.id;
    },
  );

  // get permissions added by role
  const roles = choosenFacilityPermissions.roles.map(role =>
    rolesData.find(({ id }) => id === role),
  );

  const rolesPermissions = roles.reduce((accumulator, { permissions }) => {
    return accumulator.concat(permissions);
  }, []);

  // join permissions added by role and permissions extending role
  const permissionsAndRolePermissions = [
    ...choosenFacilityPermissions.permissions,
    ...rolesPermissions,
  ];

  if (permissionsAndRolePermissions.includes(allowedPermission)) {
    return true;
  }

  return false;
};
