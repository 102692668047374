import axios from 'axios';

const PATH = '/rooms';

export const RoomsService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  create: async payload =>
    await axios.post(`${PATH}?createTrays=true`, payload),
  update: async payload =>
    await axios.put(PATH, payload).then(res => {
      if (res?.status !== 200 && res?.status !== 204) {
        return Promise.reject(res);
      }
      return res.data;
    }),
  delete: async id => await axios.delete(`${PATH}/${id}/?deleteTrays=true`),
};
