import axios from 'axios';

const PATH = '/facilities';

export const FacilitiesService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  create: async payload =>
    await axios.post(`${PATH}?createRooms=true`, payload),
  update: async (payload, resetFacility) =>
    await axios.put(PATH, payload, {
      params: {
        resetFacility: resetFacility,
      },
    }),
  delete: async id => await axios.delete(`${PATH}/${id}?totalDelete=true`),
};
