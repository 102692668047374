import axios from 'axios';

const PATH = '/recipe_environmental';

export const EnvironmentalRecipesService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  update: payload => axios.put(PATH, payload),
  create: payload => axios.post(PATH, payload),
  delete: id => axios.delete(`${PATH}/${id}`),
};
