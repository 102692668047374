import React, { memo } from 'react';

export const PreLoaderWidget = memo(({ hidden, height }) => (
  <div
    hidden={hidden}
    className="preloader"
    style={{ padding: '100px', height, zIndex: 1 }}
  >
    <div className="status">
      <div
        className="spinner-border avatar-sm text-primary m-2"
        role="status"
      />
    </div>
  </div>
));

PreLoaderWidget.displayName = 'PreLoaderWidget';
