import React, { useContext, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import {
  Box,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  Button,
  Divider,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloudIcon from '@mui/icons-material/Cloud';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SettingsIcon from '@mui/icons-material/Settings';
import TaskIcon from '@mui/icons-material/Task';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WorkIcon from '@mui/icons-material/Work';

import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';

import { useRolesStore } from '@features/roles/hooks';

import { UserContext } from '@/providers/UserProvider';
import { checkPermissionAccess } from '@/utils/checkPermissionAccess';
import { profileNav } from '@utils/profileNav';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexDirection: ({ isMobileView }) => (isMobileView ? 'column' : 'row'),
    justifyContent: ({ isMobileView }) =>
      isMobileView ? 'center' : 'flex-start',
    alignItems: ({ isMobileView, anchorEl }) =>
      isMobileView && anchorEl ? 'flex-end' : 'flex-start',
    padding: ({ isMobileView }) => (isMobileView ? '10px' : '0.3rem 0px'),
    marginBottom: 0,
    listStyle: 'none',
    backgroundColor: '#262527',
    borderRadius: 0,
  },
}));

export const links = [
  {
    name: 'Configuration',
    icon: <SettingsIcon style={{ marginRight: '5px' }} />,
    root: 'configuration',
    children: [
      {
        link: '/configuration/facility',
        name: 'Facilities',
        allowedPermission: 'CONFIGURATION_FACILITIES_TAB',
      },
      {
        link: '/configuration/irrigationZones',
        name: 'Irrigation Zones',
        allowedPermission: 'CONFIGURATION_IRRIGATION_ZONES_TAB',
      },
      {
        link: '/configuration/lightZones',
        name: 'Light Zones',
        allowedPermission: 'CONFIGURATION_LIGHT_ZONES_TAB',
      },
      {
        link: '/configuration/timeControlZones',
        name: 'Time Control Zones',
        allowedPermission: 'CONFIGURATION_TIME_CONTROL_ZONES_TAB',
      },
      {
        link: '/configuration/environments',
        name: 'Environmental Sensors',
        allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_SENSORS_TAB',
      },
      {
        link: '/configuration/environmentalZones',
        name: 'Environmental Zones',
        allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_ZONES_TAB',
      },
      {
        link: '/configuration/environmentalControlZones',
        name: 'Environmental Control Zones',
        allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_CONTROL_ZONES_TAB',
      },
      {
        link: '/configuration/rooms',
        name: 'Rooms',
        allowedPermission: 'CONFIGURATION_ROOMS_TAB',
      },
      {
        link: '/configuration/modbus',
        name: 'Modbus',
        allowedPermission: 'CONFIGURATION_MODBUS_TAB',
      },
      {
        link: '/configuration/waterSensorInterfaces',
        name: 'Water Sensor Interfaces',
        allowedPermission: 'CONFIGURATION_WATER_SENSOR_INTERFACES_TAB',
      },
      {
        link: '/configuration/waterSensors',
        name: 'Water Sensors',
        allowedPermission: 'CONFIGURATION_WATER_SENSORS_TAB',
      },
      {
        link: '/configuration/waterQualityZones',
        name: 'Water Quality Zones',
        allowedPermission: 'CONFIGURATION_WATER_QUALITY_ZONES_TAB',
      },
      {
        link: '/configuration/measurables',
        name: 'Measurables',
        allowedPermission: 'CONFIGURATION_MEASURABLES_TAB',
      },
      {
        link: '/configuration/strains',
        name: 'Strains',
        allowedPermission: 'CONFIGURATION_STRAINS_TAB',
      },
      {
        link: '/configuration/tray-types',
        name: 'Tray Types',
        allowedPermission: 'CONFIGURATION_TRAY_TYPES_TAB',
      },
      {
        link: '/configuration/product-types',
        name: 'Product Types',
        allowedPermission: 'CONFIGURATION_PRODUCT_TYPES_TAB',
      },
      {
        link: '/configuration/product-trees',
        name: 'Product Trees',
        allowedPermission: 'CONFIGURATION_PRODUCT_TREES_TAB',
      },
      {
        link: '/configuration/product-models',
        name: 'Product Models',
        allowedPermission: 'CONFIGURATION_PRODUCT_TREES_TAB',
      },
      {
        link: '/configuration/product-allocations',
        name: 'Product Allocations',
        allowedPermission: 'CONFIGURATION_PRODUCT_ALLOCATIONS_TAB',
      },
      {
        link: '/configuration/product-locations',
        name: 'Product Locations',
        allowedPermission: 'CONFIGURATION_PRODUCT_LOCATIONS_TAB',
      },
      {
        link: '/configuration/product-processors',
        name: 'Product Processors',
        allowedPermission: 'CONFIGURATION_PRODUCT_PROCESSORS_TAB',
      },
      {
        link: '/configuration/product-yields',
        name: 'Product Yields',
        allowedPermission: 'CONFIGURATION_PRODUCT_YIELDS_TAB',
      },
    ],
  },
  {
    name: 'Overview',
    icon: <DashboardIcon style={{ marginRight: '5px' }} />,
    root: 'overview',
    children: [
      {
        link: '/overview/facility',
        name: 'Facility',
        allowedPermission: 'OVERVIEW_FACILITY_TAB',
      },
      {
        link: '/overview/cultivationGroups',
        name: 'Cultivation Groups',
        allowedPermission: 'OVERVIEW_CULTIVATION_GROUPS_TAB',
      },
    ],
  },
  {
    name: 'Trends',
    icon: <TrendingUpIcon style={{ marginRight: '5px' }} />,
    root: 'trends',
    children: [
      {
        link: '/trends/environmental_zones',
        name: 'Environmental Trends',
        allowedPermission: 'TRENDS_ENVIRONMENTAL_TRENDS_TAB',
      },
      {
        link: '/trends/irrigation_zones',
        name: 'Irrigation Zone Trends',
        allowedPermission: 'TRENDS_IRRIGATION_ZONE_TRENDS_TAB',
      },
      {
        link: '/trends/water_quality',
        name: 'Water Quality Trends',
        allowedPermission: 'TRENDS_WATER_QUALITY_TRENDS_TAB',
      },
    ],
  },
  {
    name: 'Logs',
    icon: <ListIcon style={{ marginRight: '5px' }} />,
    root: 'logs',
    children: [
      {
        link: '/logs/irrigation_logs',
        name: 'Irrigation Logs',
        allowedPermission: 'LOGS_IRRIGATION_LOGS_TAB',
      },
      {
        link: '/logs/product-adjustments',
        name: 'Product Adjustments',
        allowedPermission: 'LOGS_PRODUCT_ADJUSTMENTS_TAB',
      },
    ],
  },
  {
    name: 'Recipes',
    icon: <MenuBookIcon style={{ marginRight: '5px' }} />,
    root: 'recipes',
    children: [
      {
        link: '/recipes/cultivationRecipes',
        name: 'Cultivation Recipes',
        allowedPermission: 'RECIPES_CULTIVATION_RECIPES_TAB',
      },
    ],
  },
  {
    name: 'Planning',
    icon: <EventIcon style={{ marginRight: '5px' }} />,
    root: 'planning',
    children: [
      {
        link: '/planning/cultivationGroup',
        name: 'Cultivation Group',
        allowedPermission: 'PLANNING_CULTIVATION_GROUP_TAB',
      },
    ],
  },
  {
    name: 'Forecasting',
    icon: <CloudIcon style={{ marginRight: '5px' }} />,
    root: 'forecasting',
    children: [
      {
        link: '/forecasting',
        name: 'Forecasting',
        allowedPermission: 'FORECASTING_FORECASTING_TAB',
      },
      {
        link: '/forecasting/plant-movement',
        name: 'Plant Movement',
        allowedPermission: 'FORECASTING_PLANT_MOVEMENT_TAB',
      },
      {
        link: '/forecasting/clone-production',
        name: 'Propagation Forecasted',
        allowedPermission: 'FORECASTING_PROPAGATION_FORECASTED_TAB',
      },
    ],
  },
  {
    name: 'Tasks',
    icon: <TaskIcon style={{ marginRight: '5px' }} />,
    root: 'tasks',
    children: [
      {
        link: '/tasks/task-templates',
        name: 'Task Templates',
        allowedPermission: 'TASKS_TASK_TEMPLATES_TAB',
      },
      {
        link: '/tasks/task-list',
        name: 'Tasks',
        allowedPermission: 'TASKS_TASKS_TAB',
      },
      {
        link: '/tasks/task-load-balancing',
        name: 'Task Load Balancing',
        allowedPermission: 'TASKS_TASK_LOAD_BALANCING_TAB',
      },
    ],
  },
  {
    name: 'Inventory',
    icon: <WorkIcon style={{ marginRight: '5px' }} />,
    root: 'inventory',
    children: [
      {
        link: '/inventory/plant-inventory',
        name: 'Plant Inventory',
        allowedPermission: 'INVENTORY_PLANT_INVENTORY_TAB',
      },
      {
        link: '/inventory/product-inventory',
        name: 'Product Inventory',
        allowedPermission: 'INVENTORY_PRODUCT_INVENTORY_TAB',
      },
      {
        link: '/inventory/aging-inventory',
        name: 'Aging Inventory',
        allowedPermission: 'INVENTORY_AGING_INVENTORY_TAB',
      },
      {
        link: '/inventory/current-product-inventory',
        name: 'Current Product Inventory',
        allowedPermission: 'INVENTORY_CURRENT_PRODUCT_INVENTORY_TAB',
      },
      {
        link: '/inventory/projected-product-inventory',
        name: 'Projected Product Inventory',
        allowedPermission: 'INVENTORY_PROJECTED_PRODUCT_INVENTORY_TAB',
      },
      {
        link: '/inventory/containers',
        name: 'Containers',
        allowedPermission: 'INVENTORY_CONTAINERS_TAB',
      },
    ],
  },
  {
    name: 'Performance',
    icon: <BarChartIcon style={{ marginRight: '5px' }} />,
    root: 'performance',
    children: [
      {
        link: '/performance/strain-catalog',
        name: 'Strain Catalog',
        allowedPermission: 'PERFORMANCE_STRAIN_CATALOG_TAB',
      },
      {
        link: '/performance/cultivation-groups',
        name: 'Cultivation Groups',
        allowedPermission: 'PERFORMANCE_CULTIVATION_GROUPS_TAB',
      },
      {
        link: '/performance/processing',
        name: 'Processing Performance',
        allowedPermission: 'PERFORMANCE_PROCESSING_TAB',
      },
      {
        link: '/performance/yield-analysis',
        name: 'Yield Analysis',
        allowedPermission: 'PERFORMANCE_YIELD_ANALYSIS_TAB',
      },
    ],
  },
];

export const Navbar = ({ isMenuOpened, menuToggle }) => {
  const isMobileView = useMediaQuery('(max-width:1000px)');
  const { currentUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placeholderProps, setPlaceholderProps] = useState(null);
  const classes = useStyles({ isMobileView, anchorEl });
  const { facility } = useContext(UserContext);
  const { rolesData } = useRolesStore();

  const handleMenuOpen = event => {
    setPlaceholderProps(event.currentTarget.getBoundingClientRect());
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="topbar-menu">
      <div className="container-fluid">
        {!isMobileView && (
          <ul className={classes.list}>
            {links.map((item, index) => {
              const isVisible = !item.children.every(child => {
                return !checkPermissionAccess(
                  child.allowedPermission,
                  currentUser,
                  facility,
                  rolesData,
                  child.adminOnly,
                );
              });
              return (
                isVisible && (
                  <li key={index} style={{ alignSelf: 'center' }}>
                    <Button
                      id={item.name}
                      variant="text"
                      sx={{ textTransform: 'none', color: '#efefec' }}
                      onClick={handleMenuOpen}
                      onMouseOver={handleMenuOpen}
                    >
                      {item.icon}
                      {item.name}
                      <ExpandMoreIcon />
                    </Button>
                    <Popper
                      open={anchorEl?.id === item.name}
                      anchorEl={anchorEl?.id === item.name ? anchorEl : null}
                      disablePortal
                    >
                      <Box onMouseLeave={handleMenuClose}>
                        {anchorEl?.id === item.name && (
                          <Box
                            sx={{
                              position: 'absolute',
                              width: placeholderProps?.width,
                              height: placeholderProps?.height,
                              top: placeholderProps?.height * -1,
                              left: '50%',
                              transform: 'translateX(-50%)',
                            }}
                          ></Box>
                        )}
                        <Paper
                          sx={{
                            maxHeight: 'calc(100vh - 126px)',
                            overflow: 'auto',
                          }}
                        >
                          <MenuList>
                            {item.children.map((childItem, indexChild) => (
                              <MenuItem
                                key={childItem.name + indexChild}
                                component={RouterLink}
                                to={childItem.link}
                                onClick={handleMenuClose}
                                selected={true}
                                sx={{
                                  display: !checkPermissionAccess(
                                    childItem.allowedPermission,
                                    currentUser,
                                    facility,
                                    rolesData,
                                    childItem.adminOnly,
                                  )
                                    ? 'none'
                                    : 'block',
                                }}
                              >
                                <ListItemText
                                  primaryTypographyProps={{
                                    fontSize: '14px',
                                  }}
                                  primary={childItem.name}
                                />
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Paper>
                      </Box>
                    </Popper>
                  </li>
                )
              );
            })}
          </ul>
        )}
        <Drawer
          open={isMenuOpened}
          anchor={'top'}
          onClose={menuToggle}
          PaperProps={{ sx: { borderRadius: '0px' } }}
        >
          {isMobileView && !anchorEl && (
            <ul className={classes.list}>
              {[...links, profileNav(currentUser)].map((item, index, array) => {
                const isVisible = !item.children.every(child => {
                  return !checkPermissionAccess(
                    child.allowedPermission,
                    currentUser,
                    facility,
                    rolesData,
                    child.adminOnly,
                  );
                });
                return (
                  isVisible && (
                    <li key={index} style={{ width: '100%' }}>
                      <Button
                        id={item.name}
                        variant="text"
                        sx={{
                          textTransform: 'none',
                          color: '#efefec',
                          width: '100%',
                          justifyContent: 'flex-start',
                        }}
                        onClick={handleMenuOpen}
                      >
                        {item.icon}
                        {item.name}
                      </Button>
                      {index < array.length - 1 && <Divider />}
                    </li>
                  )
                );
              })}
            </ul>
          )}
          {isMobileView && anchorEl && (
            <>
              <div
                style={{
                  backgroundColor: '#262527',
                  color: 'rgba(255, 255, 255, 0.6)',
                }}
              >
                <Button
                  variant="text"
                  onClick={handleMenuClose}
                  sx={{ color: '#efefec' }}
                >
                  <ArrowBackIcon />
                </Button>
                <span style={{ marginLeft: '-8px' }}>{anchorEl.id + ' /'}</span>
              </div>
              <ul className={classes.list}>
                {[...links, profileNav(currentUser)]
                  .find(({ name }) => name === anchorEl.id)
                  ?.children?.map((item, index, array) => (
                    <li
                      key={index}
                      hidden={
                        !checkPermissionAccess(
                          item.allowedPermission,
                          currentUser,
                          facility,
                          rolesData,
                          item.adminOnly,
                        )
                      }
                      style={{ width: '100%' }}
                    >
                      <Button
                        id={item.name}
                        variant="text"
                        component={RouterLink}
                        to={item.link}
                        sx={{
                          textTransform: 'none',
                          color: '#efefec',
                          width: '100%',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {item.name}
                      </Button>
                      {index < array.length - 1 && <Divider />}
                    </li>
                  ))}
              </ul>
            </>
          )}
        </Drawer>
      </div>
    </div>
  );
};
