import { APIService } from '../services/api.service';

export async function loadUninitializedAndDisconnectedControllers(facilityId) {
  let uninitSensors = [];
  let disconnectedEnvs = [];

  const environmentsResult = await APIService.ENVIRONMENTS.list({
    facility_id: facilityId,
    assign_sensor_values: true,
  });

  const disconnected = await APIService.DEVICE_STATUS.list({
    facilityId,
    errorType: 'disconnected',
  });
  const uninitialized = await APIService.DEVICE_STATUS.list({
    facilityId,
    errorType: 'uninitialized',
  });

  const uninitializedDevicesRegisters = await Promise.all(
    uninitialized.map(device => {
      return APIService.DEVICES.REGISTERS.get(device.cfg_id, device.registerId);
    }),
  );

  uninitializedDevicesRegisters.forEach(device => {
    if (device?.data) {
      const env = environmentsResult.find(
        env => env.id === device.data.assignmentId,
      );
      if (env && !uninitSensors.find(u => u.id === env.id)) {
        const envCopy = { ...env };
        envCopy.parameterType = device.data.parameterType
          ? device.data.parameterType
          : device.data.parameter_type
          ? device.data.parameter_type
          : 'N/A';
        uninitSensors.push(envCopy);
      }
    }
  });

  const disconnectedDevicesRegisters = await Promise.all(
    disconnected.map(device => {
      return APIService.DEVICES.REGISTERS.get(device.cfg_id, device.registerId);
    }),
  );

  disconnectedDevicesRegisters.forEach(device => {
    if (device?.data) {
      const env = environmentsResult.find(
        env => env.id === device.data.assignmentId,
      );
      if (env && !disconnectedEnvs.find(e => e.id === env.id)) {
        let envCopy = { ...env };

        // check if disconnected sensor also has an uninit device
        if (uninitSensors.find(s => s.id === env.id)) {
          envCopy.displayParameterType = true;

          const sensor = uninitSensors.find(s => s.id === env.id);
          sensor.displayParameterType = true;

          uninitSensors = uninitSensors.filter(s => s.id !== sensor.id);
          uninitSensors.push(sensor);
        }

        envCopy.parameterType = device.data.parameterType
          ? device.data.parameterType
          : device.data.parameter_type
          ? device.data.parameter_type
          : 'N/A';
        disconnectedEnvs.push(envCopy);
      }
    }
  });

  return { uninitSensors, disconnectedEnvs };
}
