import axios from 'axios';

const PATH = '/recipe_cultivation_products';

export const ProductsForecastingService = {
  list: async params => (await axios.get(PATH, { params })).data,
  create: payload => axios.post(PATH, payload),
  update: (id, payload) => axios.patch(`${PATH}/${id}`, payload),
  delete: id => axios.delete(`${PATH}/${id}`),
};
