import axios from 'axios';

const PATH = '/cultivation/flow';

export const FlowsService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(`${PATH}`, { params })).data,
  create: async payload => await axios.post(PATH, payload),
  update: async (id, payload) => await axios.patch(`${PATH}/${id}`, payload),
  roomShift: async payload => await axios.post(`${PATH}/room-shift`, payload),
  bulkUpdate: async payload => await axios.post(`${PATH}/bulk_update`, payload),
  delete: async id => await axios.delete(`${PATH}/${id}`),
};
