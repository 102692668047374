import axios from 'axios';

const PATH = '/history/environments';

export const EnvironmentsHistoryService = {
  query: async options => {
    if (options.createNew) {
      return await axios.post(PATH, options);
    } else {
      return (
        await axios.post(PATH, {
          environmentalZoneIds: options.environmentalZoneIds,
          environmentIds: options.environmentIds,
          startTime: options.startTime,
          endTime: options.endTime,
          discriminator: options.discriminator,
          lastHistoryOnly: options.lastHistoryOnly,
        })
      ).data;
    }
  },
};
