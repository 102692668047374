import { useState } from 'react';

export const useConfirmationDialog = () => {
  const [state, setState] = useState({
    isOpen: false,
    title: '',
    content: null,
    onClose: () => {},
    onConfirm: () => {},
  });

  return {
    open(config) {
      setState({
        ...config,
        isOpen: true,
      });
    },
    close() {
      setState(prevState => ({
        ...prevState,
        isOpen: false,
      }));
    },
    internalState: state,
  };
};

export const bindConfirmationDialogState = state => ({
  ...state.internalState,
  onClose: () => {
    state.internalState.onClose && state.internalState.onClose();
    state.close();
  },
  onConfirm: data => {
    state.internalState.onConfirm && state.internalState.onConfirm(data);
    state.close();
  },
});
