import axios from 'axios';

const PATH = '/environmental_control_zones';

export const EnvironmentalControlZonesService = {
  list: async params => (await axios.get(PATH, { params })).data,
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  create: async payload => await axios.post(PATH, payload),
  update: async payload => await axios.put(PATH, payload),
  patch: async (id, payload) => await axios.patch(`${PATH}/${id}`, payload),
  delete: async id => await axios.delete(`${PATH}/${id}`),
};
