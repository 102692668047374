import axios from 'axios';

const PATH = '/history/water_sensors';

export const WaterSensorsHistoryService = {
  query: async options => {
    if (options.createNew) {
      return await axios.post(PATH, options);
    } else {
      return (
        await axios.post(PATH, {
          waterSensorIds: options.waterSensorIds,
          waterSensorInterfaceIds: options.waterSensorInterfaceIds,
          startTime: options.startTime,
          endTime: options.endTime,
          discriminator: options.discriminator,
          lastHistoryOnly: options.lastHistoryOnly,
        })
      ).data;
    }
  },
};
