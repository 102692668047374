import moment from 'moment-timezone';

export function findInactiveControllers(controllers) {
  return controllers.filter(({ lastActive }) => {
    if (!lastActive || !lastActive._seconds) {
      return false;
    }

    const lastActiveTime = moment(lastActive._seconds * 1000);
    const differenceMinutes = moment().diff(lastActiveTime, 'minutes');

    return differenceMinutes >= 10;
  });
}
