import { useContext } from 'react';

import { UserContext } from '@providers/UserProvider';

export const RightSidebarContent = () => {
  const { currentUser } = useContext(UserContext);

  return (
    <div className="user-box">
      <h5>
        <a href="/">{currentUser ? currentUser.displayName : 'User'}</a>{' '}
      </h5>
      <p className="text-muted mb-0">
        <small>User</small>
      </p>
    </div>
  );
};
