import axios from 'axios';

const PATH = '/water_quality_zone';

export const WaterQualityZonesService = {
  list: async params => (await axios.get(PATH, { params })).data,
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  create: async payload => (await axios.post(PATH, payload)).data,
  update: async payload => (await axios.put(PATH, payload)).data,
  delete: async id => await axios.delete(`${PATH}/${id}`),
};
