import React, { memo } from 'react';

export const Footer = memo(() => (
  <footer className="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">2020 &copy; Greener Gardens</div>
      </div>
    </div>
  </footer>
));

Footer.displayName = 'Footer';
