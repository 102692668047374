import axios from 'axios';

const PATH = '/type_room';

export const RoomTypesService = {
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  list: async params => (await axios.get(PATH, { params })).data,
  create: async payload => await axios.post(PATH, payload),
  update: async payload => await axios.put(PATH, payload),
  delete: async id => await axios.delete(`${PATH}/${id}`),
};
