import axios from 'axios';

const PATH = '/recipe/cultivation_cycle';

export const CultivationRecipesService = {
  list: async params => (await axios.get(PATH, { params })).data,
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  create: payload => axios.post(PATH, payload),
  update: payload => axios.patch(PATH, payload),
  archive: id => axios.delete(`${PATH}/${id}`),
  copy: id => axios.post(`${PATH}/${id}/copy`),
  deployChanges: payload => axios.post(`${PATH}/deploy-changes`, payload),
  getRevisions: async id => (await axios.get(`${PATH}/${id}/revisions`)).data,
  getRelativesDifferences: async ({ active_recipe_id, ...params }) =>
    (
      await axios.get(`${PATH}/${active_recipe_id}/relatives-differences`, {
        params,
      })
    ).data,
};
