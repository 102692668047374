import axios from 'axios';

const PATH = '/rolesv2';

export const RolesService = {
  list: async params => (await axios.get(PATH, { params })).data,
  add: async params => await axios.post(PATH, params),
  delete: async params => await axios.delete(PATH, { params }),
  update: async params => await axios.patch(PATH, params),
};
